<template>
  <a-drawer :visible="visible" @close="close" width="50%" :mask-closable="false"
            :get-container="false" :closable="false"
            :wrap-style="{ position: 'absolute' }"
            :header-style="{position: 'sticky', top: 0, zIndex: 100,}">
    <div slot="title" class="title-container">
      <a-page-header title="字典管理" :sub-title="edit ? '编辑' : '详情'" style="border: none;width: 100%;padding: 8px 0;">
        <template slot="extra">
          <a-button v-if="edit" type="primary"
                    :loading="formLoading" icon="check" @click="handleSave">保存</a-button>
          <a-button v-else type="primary" icon="form" @click="toEdit">编辑</a-button>
          <a-button icon="close" @click="close">取消</a-button>
        </template>
      </a-page-header>
    </div>
    <div>
      <a-form v-if="edit" :form="form" layout="vertical">
        <a-row :gutter="16">
          <a-col v-if="!!parentData.id" :span="24">
            <a-form-item label="上级字典">
              <a-input :value="parentData.label" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="字典码">
              <a-input-group compact>
                <a-input v-if="!!parentData.key" style="width: 20%" :value="`${parentData.key}_`" disabled />
                <a-input :style="{width: !!parentData.key ? '80%' : '100%'}"
                         v-decorator="[ 'key', { rules: [{ required: true, message: '请输入字典码!' }] }, ]"
                         placeholder="请输入字典码" />
              </a-input-group>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="字典名称">
              <a-input
                  v-decorator="[ 'label', { rules: [{ required: true, message: '请输入字典名称!' }] }, ]"
                  placeholder="请输入字典名称"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="字典值">
              <a-input
                  v-decorator="[ 'value', { rules: [{ required: true, message: '请输入字典值!' }] }, ]"
                  placeholder="请输入字典值"
              />
            </a-form-item>
          </a-col>
<!--          <a-col v-for="field in formFields" :key="field.dataIndex" :span="field.formConfig.span || 24">-->
<!--            <a-form-item :label="field.title">-->
<!--              <a-textarea v-if="field.formConfig.comp === 'textarea'"-->
<!--                          v-decorator="[ field.dataIndex, { rules: field.formConfig.rules }, ]"-->
<!--                          :auto-size="{ minRows: 2, maxRows: 6 }"-->
<!--                          :placeholder="`请输入${field.title}`"-->
<!--                          :maxLength="field.formConfig.maxLen || 1000" />-->
<!--              <a-input v-else-->
<!--                  v-decorator="[ field.dataIndex, { rules: field.formConfig.rules }, ]"-->
<!--                  :placeholder="`请输入${field.title}`" />-->
<!--            </a-form-item>-->
<!--          </a-col>-->
        </a-row>
      </a-form>
      <template v-else>
        <a-descriptions layout="vertical" bordered>
          <a-descriptions-item v-for="field in detailFields" :key="field.dataIndex" :label="field.title">
            {{ detailData[field.dataIndex] }}
          </a-descriptions-item>
        </a-descriptions>
        <div class="detail-bottom"
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          创建时间：{{ detailData.createdTime | relativeTime }} 更新时间：{{ detailData.updatedTime | relativeTime }}
        </div>
      </template>
    </div>
  </a-drawer>
</template>

<script>
import {detail, save} from "@/api/service/dictionary";
import {isEmpty} from "lodash";
import {formFieldHandler} from "@/utils/form";
import {columns} from "@/views/dictionary/options";
import {detailFieldHandler} from "@/utils/detail";

export default {
  data() {
    return {
      visible: false,
      edit: false,
      formFields: formFieldHandler(columns),
      detailFields: detailFieldHandler(columns),
      form: this.$form.createForm(this, {name: 'website_form'}),
      formLoading: false,
      detailData: {},
      parentData: {},
      websiteId: '',
    }
  },
  methods: {
    open({edit, record, websiteId, addChild}) {
      this.visible = true
      this.edit = edit
      if(!isEmpty(record)) {
        if(addChild) {
          this.parentData = record
        } else {
          this.detailData = record
        }
      }
      if (edit && !isEmpty(this.detailData)) {
        this.recoveryData()
      }
      this.websiteId = websiteId
    },
    close() {
      this.visible = false
    },
    toEdit() {
      this.edit = true
      this.recoveryData()
    },
    recoveryData() {
      this.$nextTick(() => {
        this.form.setFieldsValue({
          label: this.detailData.label,
          value: this.detailData.value,
          key: this.detailData.key
        })
      })
    },
    async reloadDetail(result) {
      console.log(result);
      const { success, data } = await detail({id: this.detailData.id || result.id})
      if(success) {
        this.detailData = data
      }
    },
    handleSave() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
          this.doSave(values)
        }
      })
    },
    async doSave(values) {
      const params = {
        ...values,
        websiteId: this.websiteId
      }
      if(!isEmpty(this.parentData)) {
        params['parentId'] = this.parentData.id
      }
      if (this.detailData.id) {
        params.id = this.detailData.id
      }
      this.formLoading = true
      const {success, data} = await save(params)
      if (success) {
        this.$notification.success({
          message: '操作成功',
          duration: 2,
          onClose: () => {
            this.reloadDetail(data)
            this.edit = false
            this.formLoading = false
            this.$emit('success')
          }
        });
      }
    }
  }
}
</script>

<style>
.detail-bottom{
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  z-index: 1;
}
</style>
