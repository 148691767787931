<template>
  <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: '16px',}">
    <a-row :gutter="16">
      <a-col :span="5">
        <website-list @change="handleWebsiteChange" />
      </a-col>
      <a-col :span="19">
        <a-row type="flex" align="middle">
          <a-col :span="24" :md="12">
            <h5 class="font-semibold m-0">{{ moduleName }}</h5>
          </a-col>
          <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
            <a-button type="primary" icon="plus" @click="toEdit({})">创建{{ moduleName }}</a-button>
          </a-col>
        </a-row>
        <a-table :loading="tableLoading" :columns="finalColumns" :data-source="datasource"
                 :pagination="false"
                 :rowKey="record => record.id">
          <template slot="time" slot-scope="text">
            <time-tag :time="text" />
          </template>
          <template slot="action" slot-scope="row">
            <a-button type="link" size="small" :data-id="row.key" icon="eye" @click="toDetail(row)">
              查看
            </a-button>
            <a-button type="link" size="small" :data-id="row.key" icon="plus" @click="toEdit(row, true)">
              新增子{{ moduleName }}
            </a-button>
            <a-button type="link" size="small" :data-id="row.key" icon="form" @click="toEdit(row)">
              编辑
            </a-button>
            <a-button type="link" size="small" class="danger-button" :data-id="row.key" icon="delete"
                      @click="doDelete(row, 'label')">
              删除
            </a-button>
          </template>
        </a-table>
      </a-col>
    </a-row>
    <ActDrawer ref="ActDrawerRef" @success="loadData" />
  </a-card>
</template>

<script>
import {ENUM_STATE} from "@/model/constant";
import ActDrawer from "@/views/dictionary/ActDrawer.vue";
import {tableMixin} from "@/mixin/table";
import {columns} from "@/views/dictionary/options";
import {remove, tree} from "@/api/service/dictionary";
import TimeTag from "@/components/table/time-tag.vue";
import WebsiteList from "@/components/table/website-list.vue";

export default {
  components: {WebsiteList, TimeTag, ActDrawer},
  mixins: [tableMixin],
  data() {
    return {
      moduleName: '字典',
      requestType: 'tree',
      columns,
      ENUM_STATE,
      immediate: false,
    }
  },
  methods: {
    tree,
    remove,
    toEdit(record, addChild = false) {
      this.$refs.ActDrawerRef.open({
        edit: true,
        record,
        addChild,
        websiteId: this.treeParams.websiteId,
      })
    },
    toDetail(record) {
      this.$refs.ActDrawerRef.open({
        edit: false,
        record
      })
    },
    handleWebsiteChange(website) {
      this.treeParams = {
        websiteId: website.id
      }
      this.loadData()
    },
  },
}

</script>

