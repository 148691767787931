// {
//   "id": "3213876907003084802",
//   "parentId": "0",
//   "path": "/3213876907003084802",
//   "icon": "http://dummyimage.com/100x100",
//   "name": "一级栏目",
//   "sort": 1,
//   "websiteId": "3213873349201494018",
//   "websiteName": "盈标网",
//   "websiteDomain": "www.yungbiaodata.com",
// },

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    showPos: {
      table: false,
      form: false,
    }
  },
  {
    title: '字典码',
    dataIndex: 'key',
    showPos: {
      table: true,
      form: true,
      detail: true,
    },
    formConfig: {
      rules: [{ required: true, message: '请输入站点名称!' }]
    },
    dataConfig: {}
  },
  {
    title: '字典名称',
    dataIndex: 'label',
    showPos: {
      table: true,
      form: true,
      detail: true,
    },
    formConfig: {
      rules: [{ required: true, message: '请输入字典名称!' }]
    },
    dataConfig: {}
  },
  {
    title: '字典值',
    dataIndex: 'value',
    showPos: {
      table: true,
      form: true,
      detail: true,
    },
    formConfig: {
      rules: [{ required: true, message: '请输入站点名称!' }]
    },
    dataConfig: {}
  },
  {
    title: '创建时间',
    dataIndex: 'createdTime',
    scopedSlots: {customRender: 'time'},
    width: 100,
    showPos: {
      table: true,
      form: false,
    }
  },
  {
    title: '更新时间',
    dataIndex: 'updatedTime',
    scopedSlots: {customRender: 'time'},
    width: 100,
    showPos: {
      table: true,
      form: false,
    }
  },
]
